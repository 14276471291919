
import emailjs from '@emailjs/browser';
import {useRef} from "react";


export const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_bgv8u7l', 'template_8eh2vss', form.current, {
        publicKey: 'Y2exeBg2UofKpBYpK',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          document.getElementById('contact_sec').innerHTML = "<i>Your Email Was Sent!</i><p><small>You will be contacted soon ...</small></p>";
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert("Message NOT Sent!");
        },
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} id="contact-form">
      <div className="row gtr-uniform gtr-50">

			<div className="col-6 col-12-xsmall">
      <input type="text" id="name" name="user_name" placeholder="Name"/></div>

      <div className="col-6 col-12-xsmall">
      <input type="tel" id="phone" name="user_phone" placeholder="Phone" maxLength={10}/></div>

      <div className="col-6 col-12-xsmall">
      <input type="email" id="email" name="user_email" placeholder="Email"/></div>

      <div className="col-6 col-12-xsmall">
      <select name="user_inquiry" id="inquiry">
      <option value="">Type Of Inquiry</option>
      <option value="Basic Website">Basic Website</option>
      <option value="Site Updates">Site Updates</option>
      <option value="Wordpress">Wordpress</option>
      <option value="Wix">Wix</option>
      <option value="Square Space">Square Space</option>
      <option value="Other">Other</option>
      </select>
      </div>

      <div className="col-6 col-12-xsmall">
      <select name="user_search" id="search">
      <option value="">How Did You Find Me?</option>
      <option value="Google Search">Google Search</option>
      <option value="Yelp">Yelp</option>
      <option value="Yellow Pages">Yellow Pages</option>
      <option value="Yahoo Search">Yahoo Search</option>
      <option value="Other">Other</option>
      </select>
      </div>

      <div className="col-12">
      <textarea id="message" name="message" placeholder="Tell Me About Your Project" rows="4"/></div>
      </div>
      <p></p>
      <ul className="actions">
      <li><input type="submit" value="Send Message" /></li>
      </ul>
    </form>
  );
};
export default Contact