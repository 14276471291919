

function Footer() {

  window.onload = function DateSet() {
    const d = new Date();
    let year = d.getFullYear();
    document.getElementById("yearTxt").innerHTML = year; 
    };

  return (
    <footer id="footer">
    <div class="inner">
      <ul class="icons">
        <li><a href="https://github.com/digitalev2000" class="icon brands fa-github"><span class="label">Github</span></a></li>
        <li><a href="https://dribbble.com/berry_richard" class="icon brands fa-dribbble"><span class="label">Dribbble</span></a></li>
        <li><a href="mailto:berry.richard1@gmail.com" class="icon solid fa-envelope"><span class="label">Email</span></a></li>
      </ul>
     
      <ul class="copyright">
        <li>&copy; <span id="yearTxt"></span> Richard B.</li><li>Design: <a href="http://www.letsbuildwebsites.net/">Custom Website Redesign</a> LLC.</li>
      </ul>
    </div>
  </footer>
  )

}

export default Footer