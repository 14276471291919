
function Sidebar() {
  return (
    <header id="header">
	<div class="inner">
		<div class="picDiv">
		<h1><strong class="sidebar_heading">Welcome To</strong>&nbsp;&nbsp;&nbsp;L.B.W.</h1>
		</div>
	</div>
</header>
  )
}
export default Sidebar