import './assets/css/main.css';
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Sidebar/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
